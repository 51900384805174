import { APP_DETAILS, BANK_DETAILS } from "./types";
import { axiosService } from "../../utils/axiosService";
import { API_URLS } from "../../config/apiUrls";

export const getAppDetails = () => (dispatch) => {
  const result = axiosService.get(API_URLS.APP.APP_DETAILS);
  result.then((res) => {
    if (res?.data?.error === false) {
      dispatch({
        type: APP_DETAILS,
        payload: res?.data?.data,
      });
    }
  });
};
export const getActiveBankDetails = () => (dispatch) => {
  try {
    const result = axiosService.get(API_URLS.APP.BANK_DETAILS);
    result
      .then((res) => {
        dispatch({
          type: BANK_DETAILS,
          payload: res.data.data.bankDetails,
        });
      })
      .catch((err) => {
        console.debug(err);
      });
  } catch (err) {
    console.debug(err);
  }
};
