import React from "react";
import CashierIcon from "../assets/svg/CashierIcon";
import GamesIcon from "../assets/svg/GamesIcon";
import HomeIcon from "../assets/svg/HomeIcon";
import SportsIcon from "../assets/svg/SportsIcon";
import Exchange from "../assets/images/Exchange.png";
import Whatsapp from "../assets/images/Whatsapp.svg";
import Telegram from "../assets/images/Telegram.svg";
// import Facebook from "../assets/images/Facebook.svg";
import Instagram from "../assets/images/Instagram.svg";
import ProfileIcon from "../assets/svg/ProfileIcon";
import logo from "../assets/svg/logo_new.png";
import { useSelector } from "react-redux";
const Footer = () => {
  const { appDetails } = useSelector((state) => state.app);
  return (
    <footer className="footer">
      <div className="container">
        <div className="footer-links mb-5">
          <ul>
            <li>
              <a href="">Contact Us</a>
            </li>
            <li>
              <a href="javascript:void(0)">Privacy Policy</a>
            </li>
            <li>
              <a href="javascript:void(0)">Responsible Gaming</a>
            </li>
            <li>
              <a href="javascript:void(0)">Fair Play</a>
            </li>
            <li>
              <a href="javascript:void(0)">Games Rules</a>
            </li>
            <li>
              <a href="javascript:void(0)">Terms and Conditions</a>
            </li>
          </ul>
        </div>
        <div className="footer-logo">
          {appDetails?.LOGO_URL && <img src={appDetails?.LOGO_URL || logo} />}
        </div>
        <p>{appDetails?.FOOTER_TEXT || ""}</p>
        <div className="paymentImages mb-4">
          <ul>
            <li>
              <img src="./images/master-card.png" />
            </li>
            <li>
              <img src="./images/visa-card.png" />
            </li>
          </ul>
        </div>

        <div className="secureGaming">
          <ul>
            <li>
              <img src="./images/GA-partner.png" />
            </li>
            <li>
              <img src="./images/gamecare-partner.png" />
            </li>
            <li>
              <img src="./images/mga-partner.png" />
            </li>
            <li>
              <img src="./images/age18.png" />
            </li>
          </ul>
        </div>

        <div className="social-icons">
          <ul>
            <li>
              <div
                onClick={() =>
                  window.open("https://wa.me/916265290933", "_blank")
                }
              >
                {/* <span className="notification-count">2</span> */}
                <img src={Whatsapp} alt="Whatsapp Icon" />
              </div>
            </li>
            <li>
              <a href="javascript:void(0)">
                <img src={Telegram} alt="Telegram Icon" />
              </a>
            </li>
            {/* <li>
              <a target="_blank" href="https://www.facebook.com">
                <img src={Facebook} alt="Facebook Icon" />
              </a>
            </li> */}
            <li>
              <a target="_blank" href="https://www.instagram.com">
                <img src={Instagram} alt="Instagram Icon" />
              </a>
            </li>
          </ul>
        </div>

        <div className="footer-menu position-fixed start-0 bottom-0 z-index-2 w-100 d-block d-md-none">
          <ul className="d-flex justify-content-evenly m-0 p-0 list-unstyled">
            <li>
              <a href="/sportsbook">
                <SportsIcon />
                <span>Sports</span>
              </a>
            </li>
            <li>
              <a href="/casino/ezugi">
                <GamesIcon />
                <span>Casino</span>
              </a>
            </li>
            <li>
              <a className="active" href="/home">
                <HomeIcon />
                <span>Home</span>
              </a>
            </li>
            <li>
              <a href="/cashier">
                <CashierIcon />
                <span>Cashier</span>
              </a>
            </li>
            <li>
              <a
                href={`https://wa.me/${appDetails?.WHATS_APP_URL}`}
                target="_blank"
              >
                {/* <HeartIcon /> */}
                <ProfileIcon />
                <span>Support</span>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
