import { APP_DETAILS, BANK_DETAILS } from "./types";

const initialState = {
  appDetails:
    localStorage.getItem("appDetails") !== null
      ? JSON.parse(localStorage.getItem("appDetails"))
      : null,
  bankDetails: null,
};

export default (state = initialState, actions) => {
  const { payload, type } = actions;
  switch (type) {
    case APP_DETAILS:
      localStorage.setItem("appDetails", JSON.stringify(payload));
      return {
        ...state,
        appDetails: payload,
      };
    case BANK_DETAILS:
      return {
        ...state,
        bankDetails: payload,
      };
    default:
      return state;
  }
};
