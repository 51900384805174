import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { balanceUpdate, logoutUser } from "../redux/auth/actions";
// import {
//   getUserBalance,
//   logOut,
//   updateUserBalance,
// } from "../redux/auth/actions";
import { socket } from "../utils/socketUtil";

function SocketProvider({ children }) {
  // const userData = useSelector((state) => state.auth.user);
  const isAuth = useSelector((state) => state.auth.isAuth);
  const dispatch = useDispatch();

  useEffect(() => {
    if (isAuth) {
      // socket.on("connect", () => {
      // console.log("socket connected");
      socket.emit("session_check", (data) => {
        // console.log(data);
        if (data?.status === "failed") {
          dispatch(logoutUser());
        }
      });

      socket.emit("get:balance", (data) => {
        if (data?.status === "success") {
          // console.log(data);
          dispatch(balanceUpdate(data));
        }
      });
      // });

      socket.on("connect_error", (error) => {
        // console.log(error);
        // revert to classic upgrade
        socket.io.opts.transports = ["polling", "websocket"];
      });

      socket.on("update_balance", (data) => {
        if (!isNaN(parseFloat(data.balance))) {
          // console.log("balUp", data);
          dispatch(balanceUpdate(data));
        } else {
          socket.emit("get:balance", (data) => {
            if (data?.status === "success") {
              // console.log(data);
              dispatch(balanceUpdate(data));
            }
          });
        }
      });
    }
    // return () => {};

    return () => {
      socket.off("connect");
      socket.off("disconnect");
    };
  }, [isAuth]);

  return <>{children}</>;
}

export default SocketProvider;
