import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Offcanvas from "react-bootstrap/Offcanvas";
import MenuIcon from "../assets/svg/MenuIcon";
import ProfileIcon from "../assets/svg/ProfileIcon";
import RummyIcon from "../assets/svg/RummyIcon";
import TelegramIcon from "../assets/svg/TelegramIcon";
import WalletIcon from "../assets/svg/WalletIcon";
import WhatsappIcon from "../assets/svg/WhatsappIcon";
import BetIcon from "../assets/svg/BetIcon";
import SettingIcon from "../assets/svg/SettingIcon";
import StatementIcon from "../assets/svg/StatementIcon";
import LogoutIcon from "../assets/svg/LogoutIcon";
import GameShowIcon from "../assets/svg/GameShowIcon";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { logoutUser } from "../redux/auth/actions";
import logo from "../assets/svg/logo_new.png";
// import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import CashierIcon from "../assets/svg/CashierIcon";
import GamesIcon from "../assets/svg/GamesIcon";
import HomeIcon from "../assets/svg/HomeIcon";
// import RiExchangeFill from "../assets/svg/ProfileIcon";
// import Exchange from "../assets/images/Exchange.png";
import SportsIcon from "../assets/svg/SportsIcon";
import numberWithCommas from "../utils/numbersWithComma";
const HeaderAfterLogin = () => {
  const { user, wallet } = useSelector((state) => state.auth);
  const [show, setShow] = useState(false);
  const navigate = useNavigate();
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const dispatch = useDispatch();
  const { appDetails } = useSelector((state) => state.app);
  const [showToggle, setShowToggle] = useState(true);

  useEffect(() => {
    if (showToggle) {
      document.body.classList.add("header_open");
    } else {
      document.body.classList.remove("header_open");
    }
  }, [showToggle]);

  useEffect(() => {
    setShowToggle(window.screen.width > 991.98);
    return () => {};
  }, []);
  return (
    <header className="header aftrlgn">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="d-flex justify-content-between">
              <div className="d-flex align-items-center">
                <div className="logo d-md-block d-none">
                  <a href="/home">
                    {appDetails?.LOGO_URL && <img src={appDetails?.LOGO_URL} />}
                  </a>
                </div>
                <Navbar expand="">
                  <Navbar.Toggle
                    aria-controls="basic-navbar-nav"
                    onClick={() => setShowToggle((s) => !s)}
                  />
                  <Navbar.Collapse id="basic-navbar-nav">
                    <Nav>
                      <Nav.Link href="/home" className="logoImg">
                        {appDetails?.LOGO_URL && (
                          <img src={appDetails?.LOGO_URL} />
                        )}
                      </Nav.Link>
                      <Nav.Link href="/home">
                        <HomeIcon /> Home
                      </Nav.Link>
                      <Nav.Link href="/sportsbook">
                        <SportsIcon /> Sports
                      </Nav.Link>
                      <Nav.Link href="/casino/ezugi">
                        <RummyIcon />
                        Ezugi
                      </Nav.Link>
                      <Nav.Link href="/casino/evolution">
                        <GameShowIcon />
                        Evolution
                      </Nav.Link>
                      <Nav.Link href="/casino/ezugi">
                        <GamesIcon /> Casino
                      </Nav.Link>
                      <Nav.Link href="/cashier">
                        <CashierIcon /> Cashier
                      </Nav.Link>
                    </Nav>
                  </Navbar.Collapse>
                </Navbar>
              </div>
              <div className="logo d-md-none d-block">
                <a href="/home">
                  {appDetails?.LOGO_URL && <img src={appDetails?.LOGO_URL} />}
                </a>
              </div>
              <div className="headerRight">
                <div className="funds">
                  <ul>
                    {/* <li>
                      <a href="#">
                        Exch Bal:{" "}
                        <span>{numberWithCommas(wallet?.sports || 0)}</span>
                      </a>
                    </li> */}
                    <li>
                      <a href="#">
                        Balance:{" "}
                        <span>{numberWithCommas(wallet?.balance || 0)}</span>
                      </a>
                    </li>
                  </ul>
                </div>
                <Button variant="primary menu-btn" onClick={handleShow}>
                  <MenuIcon />
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Offcanvas show={show} onHide={handleClose} placement={"end"}>
        <Offcanvas.Header closeButton="closeButton" closeVariant="white">
          <Offcanvas.Title>
            <div className="d-flex">
              <div className="profile-img">
                <ProfileIcon />
              </div>
              <div className="user-info">
                <span>{user?.mstruserid}</span>
                <p>{user?.mobileno}</p>
              </div>
            </div>
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <div className="row">
            <div className="col-6">
              <div className="balance mb-3">Main Balance</div>
            </div>
            <div className="col-6">
              <div className="balance-amount mb-3">
                {numberWithCommas(wallet?.balance || 0)}
              </div>
            </div>
            <div
              className="col-6"
              onClick={() => {
                navigate("/withdraw");
              }}
            >
              <Button variant="outline-secondary w-100">Withdraw</Button>
            </div>
            <div
              className="col-6"
              onClick={() => {
                navigate("/deposit");
              }}
            >
              <Button variant="primary w-100">Deposit</Button>
            </div>
          </div>
          <ul className="menu-items">
            <li>
              <a href="/profile">
                <ProfileIcon />
                <span>Profile</span>
              </a>
            </li>
            <li>
              <a href="/mybets">
                <BetIcon />
                <span>My Bets</span>
              </a>
            </li>
            <li>
              <a href="/cashier">
                <StatementIcon />
                <span>Account Statement</span>
              </a>
            </li>
            {/* <li>
              <a href="#">
                <SettingIcon />
                <span>Transfer Funds ( For Excahnge)</span>
              </a>
            </li> */}
            <li>
              <a href="/settings">
                <SettingIcon />
                <span>Settings</span>
              </a>
            </li>
            <li onClick={() => dispatch(logoutUser())}>
              <a href="/">
                <LogoutIcon />
                <span>Sign Out</span>
              </a>
            </li>
          </ul>
          <ul className="social_links">
            <li>
              <a href="#">
                <TelegramIcon />
              </a>
            </li>
            {/* <li>
              <div
                onClick={() =>
                  window.open(appDetails?.WHATS_APP_URL, "_blank")
                }
              >
                <WhatsappIcon />
              </div>
            </li> */}
            <li>
              <div
                onClick={() => window.open(appDetails?.WHATS_APP_URL, "_blank")}
              >
                <span className="notification-count">2</span>
                <WhatsappIcon />
              </div>
            </li>
          </ul>
        </Offcanvas.Body>
      </Offcanvas>
    </header>
  );
};

export default HeaderAfterLogin;
