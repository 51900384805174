import io from "socket.io-client";

export const socket = io(process.env.REACT_APP_SOCKET_URL, {
  path: "/public/",
  transports: ["websocket", "polling"],
  auth: {
    token: localStorage.getItem("token"),
  },
});

export const socketFunc = (token) =>
  io(process.env.REACT_APP_SOCKET_URL, {
    path: "/public/",
    transports: ["websocket", "polling"],
    auth: {
      token: token,
    },
  });

export const sportsSocket = io(process.env.REACT_APP_SPORTS_SOCKET, {
  path: "/public/",
  transports: ["websocket", "polling"],
});
